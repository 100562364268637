import { render, staticRenderFns } from "./MyTask.vue?vue&type=template&id=aa3ca2d0&scoped=true"
import script from "./MyTask.vue?vue&type=script&lang=js"
export * from "./MyTask.vue?vue&type=script&lang=js"
import style0 from "./MyTask.vue?vue&type=style&index=0&id=aa3ca2d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa3ca2d0",
  null
  
)

export default component.exports